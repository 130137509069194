import { TAmenitiesIcons } from '@/constants/amenities';
import { IAmenity, ICategory } from '@/redux/selectors/listing/amenities';
import { IAmenityCategory, IProcessedAmenities } from '@/services/types/search/rentals/id';

export interface IAmenityList {
  categories: ICategory[];
  list: IAmenity[];
}

export const mapAmenities = (
  processedAmenities?: IProcessedAmenities,
  categories?: IAmenityCategory[],
  icons?: Record<string, TAmenitiesIcons>,
): IAmenityList => {
  if (!processedAmenities || !categories) {
    return {
      categories: [],
      list: [],
    };
  }

  const list = categories
    .map(category => {
      return processedAmenities[category.id]
        ?.map(item => ({
          id: item.id,
          name: item?.name_available ?? item.name,
          available: item.available,
          category: category.id,
          icon: icons?.[item.id]?.icon,
          BonfireIcon: icons?.[item.id]?.BonfireIcon,
          unavailableIcon: icons?.[item.id]?.unavailableIcon,
        }))
        .filter(item => item.BonfireIcon || (item.icon && item.unavailableIcon)) as IAmenity[];
    })
    .reduce((p, c) => [...p, ...c], []);

  return {
    categories,
    list,
  };
};
