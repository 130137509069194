import React from 'react';
import { useIntl } from 'react-intl';

import OutdoorsySmallLogo from '@/assets/images/outdoorsy-logo-small.svg';
import Rating from '@/components/route/listing/Rating/Rating';
import Avatar from '@/components/switchback/Avatar';
import { getAbbreviatedSurname } from '@/utility/formatters';

type IRenterProfileElement = React.HTMLAttributes<HTMLDivElement>;

export interface IProps {
  name: string;
  date: Date;
  ratingNumber?: number;
  photoUrl?: string;
  shouldRenderOutdoorsyLogo?: boolean;
}

const RenterProfile: React.FC<IProps & IRenterProfileElement> = ({
  name,
  date,
  ratingNumber,
  photoUrl,
  shouldRenderOutdoorsyLogo,
  ...props
}) => {
  const intl = useIntl();
  const formattedDate = intl.formatDate(date, {
    month: 'long',
    year: 'numeric',
  });
  const renterName = name
    ? getAbbreviatedSurname(name)
    : intl.formatMessage({
        defaultMessage: 'Anonymous Renter',
        id: 'gJQnRW',
      });

  return (
    <div data-testid="renter-profile" className="flex items-center leading-none text" {...props}>
      <div className="w-10 mr-4">
        {shouldRenderOutdoorsyLogo ? (
          <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
            <OutdoorsySmallLogo className="w-6 h-6" />
          </div>
        ) : (
          <Avatar name={renterName} photoUrl={photoUrl} size="40" />
        )}
      </div>
      <div>
        <p className="text-base h6 text">{renterName}</p>

        <p className="flex flex-wrap items-center">
          <span className="text-base text-gray-500 semiHighlight">{formattedDate}</span>
          {ratingNumber !== undefined && (
            <Rating
              className="ml-2 font-semibold text-gray-800 text body-sm"
              number={ratingNumber}
            />
          )}
        </p>
      </div>
    </div>
  );
};

export default RenterProfile;
